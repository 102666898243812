<template>
  <div class="page">
    <p style="margin-top:0pt; margin-bottom:0pt"><span>Yuliverse Privacy Policy</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>{{ compony }} Limited (the "Company") respects the
                privacy of its online visitors and customers who use its products and services (including, but not
                limited to Yuliverse) . The Company ensures compliance with applicable privacy laws that safeguard the
                protection of your privacy, including, without limitation, the European Union General Data Protection
                Regulation ("GDPR") and the Swiss and EU Privacy Shield Frameworks.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">1. Definitions</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>In the context of the following terms and conditions
                regarding Personal Data ("Personal Data"), it refers to any information that can directly identify you
                as an individual ("Personally Identifying Information") or that can be indirectly connected to you by
                linking it to Personally Identifying Information. This includes, but not limited to:</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(i) your account registration information on our website and
                in our App;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(i) any support requests or problem/error reports submitted
                to us by you; </span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(iii) information collected from using certain services,
                functionalities or features;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(iv) information gathered through the completion of surveys
                or questionnaires;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(v) technical information, such as the Internet protocol (IP)
                address used, your log-in information, browser type and version, time zone setting, browser plug-in
                types and versions, operating system, and platform;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(vi) details of any transactions, purchases, and payments you
                made;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(vii) your general interaction with the website, including
                the full Uniform Resource Locators (URLs), clickstream data to, through, and from our site, products
                viewed or searched for, page response times, download errors, length of visits to certain pages, page
                interaction information;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(ix) information received from third parties, such as
                business partners, sub-contractors, payment and delivery services, referrals by other users.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>The Company also engages in the processing of anonymous data,
                whether aggregated or not, for the purpose of analyzing and generating statistics related to the
                customer habits, usage patterns, and demographics information, both on a collective and individual basis
                of customers as a group or as individuals. Such anonymized data does not allow for the identification of
                specific customers. the Company may share such anonymized data, whether aggregated or not, with third
                parties. Please kindly note that the Company may authorize third parties to offer subscription and/or
                registration-based services through its website. The Company shall not be liable for any actions or
                policies of such third parties, and it is advisable for you to review the relevant privacy policy of
                said party when disclosing personally identifiable information.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>By accessing and using the Company's website, you acknowledge
                and accept the terms and conditions set forth in the Company's privacy policy. If you do not agree with
                these terms, we kindly request that you refrain from using the Company’s website(s).</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">2. Purpose of Data Collection and
                Processing by the Company</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>The Company collects and processes Personal Data for the
                following reasons:</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(a) fulfilling our contractual obligations to you by
                providing content and services, including the provision, enhancement and advancement of our
                services;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(b) conducting research, devising and launching new features
                or products;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(c) furnishing you with notifications, updates, materials, or
                information regarding our services or other requested information or subscriptions;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(d) retrieving outstanding amounts;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(e) responding to or participating in legal proceedings,
                including seeking legal counsel or safeguarding the lawful and legitimate interests of the Company or a
                third party (e.g. the interests of our other stakeholder(s));</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(f) adhering to the legal obligations to which we are
                bound;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(g) Engaging in communication with you and addressing your
                inquiries or requests;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(h) direct marketing - explicit consent is required for this
                purpose and you retain the right to opt-out any time;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(i) pursuits directly associated or ancillary to the
                aforementioned purposes; or</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(j) where you have granted consent for such
                activities.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>These reasons for the collection and processing Personal Data
                delineate and confined the scope of Personal Data we gather and its utilization (as detailed in section
                3 below), the duration for which it is retained ( as outlined in section 4. below), the entities or
                individuals authorized to access it (as explained in section 5 below) and rights and additional control
                mechanisms accessible to you as a user (as specified in section 6 below).</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">3. What Data We Collect and
                Process</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold; -aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>3.1 Basic Account Data</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>When establishing an Account, the Company will collect your
                email address and country of residence. You will be required to select a username and a password.
                Furnishing this information is essentail to register a User Account. It is your responsibility to
                maintain the confidentiality of your password. We kindly request that you refrain from sharing your
                password with any third party.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Upon the setup of your account, an identification number
                ("ID") will be automatically assigned to your account. This ID will subsequently be used as a reference
                for your user account without directly exposing personally identifiable information about you.</span>
        </p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>3.2 Transaction and Payment Data</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>To facilitate online transactions online, it may be necessary
                for you to provide payment data to the Company. If you opt to pay via credit card, you will need to
                disclose customary credit card details (such as name, address, credit card number, expiry date, and
                security code) to the Company. The Company will process this information and transmit it to your chosen
                payment service provider to enable the transaction and conduct anti- fraud verifications. Similarly,,
                the Company will receive data from your payment service provider for the same purposes.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>3.3 Explicitly Submitted Additional Data</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Upon your explicit provision or transmission of Personal Data
                to us, or as part of your communication with others, e.g. in forums, chats, or when you provide feedback
                or user-generated content. This data includes but not limited to:</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(a) Information posted, commented on, or followed within any
                of our Content and Services;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(b) Information conveyed via chat functionality;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(c) Information furnished when requesting information or
                support from us or when purchasing Content and Services from us, including data required to order
                processing with the relevant payment merchant or, in the case of physical goods, shipping
                providers;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(d) Information provided during participation competitions,
                contests, and tournaments or survey responses, such as contact details.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>3.4 Utilization of the Websites by You</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>through your general interaction with the websites, Content,
                and Services we offer, we gather a range of information. The Personal Data we may collect includes, but
                not limited to, browser and device details, data obtained through automated electronic interactions, and
                application usage data. We will monitor your activities across our websites and applications to ensure
                the absence of bot activity and to optimize our services.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>3.5 Utilization of Services and other Subscriptions</span>
        </p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>In order to provide you with services, it is necessary for us
                to collect, store and utilize various information pertaining to your activity within our Content and
                Services. "Content-Related Information" includes your ID, as well as information regarding your
                preferences, progress, time spent, as well as information about the device you are using, including what
                operating system you are using, device settings, unique device identifiers, and crash data.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>3.6 Tracking Data and Cookies</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>We employ "Cookies", which are text files placed on your
                computer, to assist us in analyzing user behavior within our services. Additionally, we utilize similar
                technologies (e.g., web beacons, pixels, ad tags, and device identifiers) to identify you and/or your
                device(s) across different devices and our services. These technologies aid in enhancing the services we
                offer, refining marketing strategies, conducting analytics, and optimizing website functionality. The
                use of Cookies is standard on the internet.. While most web browsers automatically accept cookies, the
                decision to accept or decline such files remains yours. You have the option to adjust your browser
                settings to reject cookies or receive notifications whenever a cookie is sent to you. It is important to
                note, however, that by doing so, you may not be able to access the full functionality of our websites.
                When you access any of our services, our servers log your global IP address, which is a number
                automatically assigned to the network your computer is connected to.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>3.7 Third Party Services</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>This website uses AWS Cloud ("Third Party Service").
                Third-Party Service uses "cookies" which are text files placed on visitors' computers, to facilitate the
                analysis of visitor behavior on the site. The information generated by these cookies regarding the
                visitors' website usage is generally transmitted to and stored by the Third-Party Service on servers
                located in Singapore or any other jurisdictions that the Third Party Services may change to from time to
                time. Please be aware that Company cannot or does not control or oversee the use of cookies or the
                resulting information by the Third Party Service.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>On behalf of the website operator, Third Party Service will
                use this information to evaluate the website / location / credentials for users. This evaluation is
                conducted to compile reports on website activity and provide additional services pertaining to website
                activity and internet usage for website operators.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Third-Party Service will not link the transferred IP address
                to any other data held by the Company. You have the option to decline the use of cookies by adjusting
                your browser settings accordingly. However, please note that in doing so, you may not be able to fully
                use the functionality offered by this website.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>The Company may change or add the Third-Party service
                provider(s) from time to time regarding to variety of services, in order to match the business
                development or provide better service to you.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>3.8 Content Recommendations</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Under section 3, the information we collect may be processed
                to tailor the content, products, and services displayed on pages and in update messages when accessing
                the service. This customization aims to meet your specific requirements and provide relevant
                recommendations and offers, thereby enhancing your customer experience.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>With your separate consent or where explicitly permitted
                by</span><span style="-aw-import:spaces">&#xa0; </span><span>applicable email marketing laws, the
                Company may send you marketing messages to your email address regarding products and services offered by
                the Company. In such a case, we may also use your collected information to personalize these marketing
                messages and gather information on whether you opened the messages and which links within the messages
                you followed.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>You have the right to opt out or withdraw your consent to
                receive marketing emails at any time. This can be done by either withdrawing your consent on the same
                page where you initially provided it or clicking the "unsubscribe" link provided in every marketing
                email. Notwithstanding any opt out of promotional or marketing emails by you, we reserve the right to
                contact you regarding account status, changes to the user agreement and other matters relevant to the
                underlying service and/or the information collected.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>3.9 Data Required for Detection of Violations</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>We collect specific data necessary for the detection,
                investigation and prevention of fraud, cheating and other violations of the applicable laws
                ("Violations"). This data is soly used for the purposes of identifying, investigating, preventing and,
                where applicable, acting against such Violations. It is</span><span style="-aw-import:spaces">&#xa0;
            </span><span>stored only for the minimum duration required to fulfil these objectives. If the data indicates
                the occurrence of a Violation has occurred, we will further retain the data for the establishment,
                exercise or defence of legal claims within the applicable statute of limitations or until the resolution
                of a legal case related to the Violation. It is important to note that certain data stored for this
                purpose may not be disclosed to you if such disclosure would compromise the mechanism by which we
                detect, investigate, and prevent such Violations.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">4. How We Store Data</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>4.1 Period of Storage</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>We will store your information as long as necessary duration
                to fulfil the purposes for which it was collected and processed or as required by applicable laws that
                mandate longer storage and retention period - for the storage and retention period required by law.
                Specifically, if you terminate your User Account, your Personal Data will be marked for deletion unless
                legal requirements or other legitimate purposes dictate a longer storage period. Please be aware that
                all your data and credits will be permanently lost upon deletion.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>4.2 Deletion of Data</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>In situations where cases where Personal Data cannot be
                completely deleted in order to ensure the consistency of the system, the user experience or the
                community integrity, your information will be irrevocably and permanently anonymized. Please note that
                the Company is obliged to retain certain transactional data in accordance with statutory commercial and
                tax laws for a period of up to Seven(7) years.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>If you withdraw your consent, which served as the basis for
                processing of your Personal Data, we will promptly delete your Personal Data without undue delay to the
                extent that the collection and processing of the Personal Data was based on the withdrawn consent. If
                you exercise a right to object to the processing of your Personal Data, we will review your objection
                and delete your Personal Data that we processed for the objected purpose without undue delay, unless
                there exists an alternative legal basis for processing and retaining such data, or unless applicable law
                mandates data retention.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>4.3 Location of Storage</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>The data we collect from you may be transferred to, and
                stored at Singapore and Hong Kong SAR, or a destination outside of your jurisdiction. It may also be
                processed by third parties operation outside of your jurisdiction. By providing your personal data you
                consent to this transfer, storing or processing of your data outside your jurisdiction. We will take all
                necessary and reasonable steps to ensure that your data is securely treated under this privacy
                policy.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">5. Who Has Access to Data</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>5.1 The Company and its subsidiaries may share your Personal
                Data among themselves and use it to the extent necessary to fulfil the purposes outlined in section 2
                above. This includes sharing with our overseas offices, affiliates, business partners and counterparts
                (on a need- to-know basis only). In the event of a restructuring, sale or merger, we may transfer
                Personal Data to the relevant or proposed transferees of our operations (or a substantial part thereof)
                in any location worldwide.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>5.2 We may also share your Personal Data with third-party
                service providers that offer customer support services in connection with goods, Content and Services
                distributed through us. Your Personal Data will be used in accordance with this Privacy Policy and only
                to the extent necessary for provision of customer support services.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>5.3 We may also share your Personal Data with our employees,
                personnel, agents, advisors, auditors, contractors, financial institutions, and service providers in
                connection with our operations or services (e.g.,staff involved in order fulfilment, payment procession
                and</span><span style="-aw-import:spaces">&#xa0; </span><span>the provision of support services);
                persons bound by confidentiality obligations to us; or individuals or entities to whom we are required
                to make disclosure under applicable laws and regulations, regardless of geographic location.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>5.4 As per industry standards, we may also share certain
                information (including your IP address and the identification of content you wish to access) with our
                third-party network providers that provide content delivery network services and server services in
                connection with us. Our content delivery network providers enable the delivery of digital content you
                have requested, by using a system of distributed servers that deliver the content to you, based on your
                geographic location.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>5.5 The Company may provide you with the option to link your
                User Account to an account offered by a third party.. If you consent to such account linking, the
                Company may collect and combine the information you have authorized the Company to receive from a third
                party with information of your User Account, to the extent permitted by your consent at that time. If
                linking of the accounts requires the transmission of your personal information from the Company to a
                third party, you will receive prior notification and opportunity to consent to the linking and
                transmission of your information. The third party's use of your information will be subject to the third
                party's privacy policy, which we encourage you to review.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>5.6 The Company may disclose Personal Data in order to comply
                with court orders, laws and regulations that require the disclosure of such information.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>5.7 We provide access to certain data related to your User
                Account to other users. This information can be accessed by anyone through querying your ID. At the very
                least, the public persona name chosen by you to represent yourself is accessible in this approach. The
                availability of additional information about you can be controlled through your user profile page; data
                publicly available on your profile page may be accessed automatically.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>While we do not knowingly share Personally Identifying
                Information such as your real name or email address, any information you choose to share about yourself
                on your public profile can be accessed, including information that could potentially identify
                you.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>5.8 The community includes message boards, forums and/or chat
                areas, where users can exchange ideas and communicate with one another. When posting a message on such
                platform, please be aware that the information becomes publicly available online, and therefore, you
                assume the associated risk(s); and that such information can be collected, correlated and used by third
                parties, potentially resulting in unsolicited messages from other participants, posters or third
                parties.These activities are beyond our control.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>If your Personal Data is posted on one of our community
                forums without your consent, please use the reporting function and the help site to request its
                removal.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">6. Your Rights and Control
                Mechanisms</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>You have the following rights:</span></p>
        <p
            style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'(%0)'; -aw-list-number-styles:'lowerLetter'; -aw-list-number-values:'1'; -aw-list-padding-sml:2.25pt">
            <span style="-aw-import:ignore"><span>(a)</span><span
                    style="width:2.25pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;
                </span></span><span>To verify whether we hold personal data about you;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(b) to access any personal data we hold about you;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(c) to request the correction of any inaccuracies or errors
                in the personal data we hold about you;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>(d) to request for the deletion of your personal data by
                deleting your User Account.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>The data protection laws in the European Economic Area and
                other jurisdictions grant their citizens specific rights regard to their Personal Data. While certain
                jurisdictions may provide fewer statutory rights to their citizens, we provide customers worldwide with
                the tools necessary to exercise such rights.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>As a resident of the European Economic Area, you hold the
                subsequent rights concerning your Personal Data.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>6.1 Right of Access</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>You have the right to access your Personal Data retained by
                us. This includes the right to require, free of charge, (i) information regarding the retention of your
                Personal Data, (il) access to your Personal Data, and/or (iii) copies of the Personal Data retained. You
                exercise your right of access to your Personal Data through the Privacy Dashboard. However, If the
                request affects the rights and freedoms of others or is manifestly unfounded or excessive, we reserve
                the right to impose a reasonable fee (considering the administrative costs of providing the information
                or communication requested) or decline to act upon the request.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>6.2 Right to Rectification</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>If we process your Personal Data, we shall make best effort
                to ensure its accuracy and up-to-date for the purposes for which it was collected, employing appropriate
                measures. Should your Personal Data be inaccurate or incomplete, you may modify the information you
                provided via the Privacy Dashboard.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>6.3. Right to Erasure</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>You have the right to request the deletion of</span><span
                style="-aw-import:spaces">&#xa0; </span><span>your Personal Data by deleting your User Account through
                the support page or function. Upon deleting your User Account, you will lose access to services,
                including the User Account, Subscriptions and service-related information associated with the User
                Account,as well as the accessibility to other services used through the User Account for.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>We provide you the option to restore your User
                Account</span><span style="-aw-import:spaces">&#xa0; </span><span>within a grace period of 30 (thirty)
                calendar days from the moment or your deletion request. This functionality prevents accidental loss of
                your account due to misplacement of account credentials or unauthorized access.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>During the suspension period, we will complete any financial
                and other activities initiated by you prior to submitting the User Account deletion request. Following
                the conclusion of the grace period, Personal Data linked to your account will be deleted in accordance
                with Section 4 above.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>In some certain scenario, the deletion of your User Account
                and subsequent erasure of Personal Data may present complexities. Depending on the situation, complexity
                and volume of deletion requests, the period for Personal Data erasure may vary or be extended, but not
                exceeding an additional two months.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>6.4 Right to Object</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>If our processing of your Personal Data is based on
                legitimate interests as outlined in Article 6(1)(f) of the GDPR / section 2.c) of this Privacy Policy,
                you have the right to object to such processing. Upon objection, we will cease processing your Personal
                Data unless there are compelling and prevailing legitimate grounds for the processing as described in
                Article 21 of the GDPR; particularly if the data is necessary for the establishment, exercise or defence
                of legal claims.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>You further have the right to lodge a complaint at a
                supervisory authority.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">7. Children</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>The minimum age to create a User Account is 18. the Company
                will not knowingly collect Personal Data from individuals below this age. In cases where certain
                countries enforce a higher age of consent for the collection of Personal Data, the Company requires
                parental consent prior to the creation of a User Account and</span><span
                style="-aw-import:spaces">&#xa0; </span><span>the collection of associated Personal Data. The Company
                encourages parents to educate their children about refraining from disclosing personal information
                online.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">8. Contact Info</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>You may reach out to the Company's data protection officer
                using the contact details provided below. While we diligently assess any request sent by mail, it is
                important to note that, in order to prevent fraud, harassment and identity theft, the only way to
                access, rectify or delete your data is by logging in with your User Account in our application.</span>
        </p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>{{ compony }} LIMITED</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Unit 1404-05, 14/F Nan Fung Towr,</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>88 Connaught Road Central</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Sheung Wan, Hong Kong</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">9. Revision Date</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>This privacy policy has been recently updated as of 01
                January 2024 ("Revision Date"). If you were a user before the Revision Date, this updated policy will
                prevail. The Company reserves the right to change this policy at any time by informing users about the
                existence of a new privacy statement. Please be aware that this policy does not aim to and shall not be
                construed as, concluding any contractual or legal rights in or behalf of any party.</span></p>
  </div>
</template>

<script>
import appBridging from "@/utils/appBridging.js";
import { IsMobile } from '@/utils/utils.js';
export default {
  name: "PrivacyPolicy",
  data() {
    return {
        compony:'METALABS COMPANY'
    };
  },
  created() {
    if(IsMobile){
          if(appBridging.isIOS){
            this.compony = 'CLOUDMATRIX HOLDINGS'
          }else{
            this.compony = 'METALABS COMPANY' 
          }
        }else{
          this.compony = 'METALABS COMPANY'
        }
    window.scrollTo(0, 0);
  },
  methods: {},
};
</script>

<style scoped lang="less">
.page {
  padding: 15px 20px 20px 20px;
  text-align: left;
  overflow-x: hidden;
//   background: #F6FAFD;
//   color: #484C58;
  color: #fff;
  background: rgba(0, 0, 0, 0);
  font-weight: 500;

  p {
    line-height: 30px;
  }

  span {
    font-family: 等线;
    font-size: 10.5pt;
  }
}
@media screen and (min-width: 1000px){
  .page{
        background: rgba(0, 0, 0, 1);        
  }
}
</style>
